import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout title="Not Found">
    <div style={{ position: 'absolute', left: 300, top: 60 }}>
      <h1>404 - PAGE NOT FOUND</h1>
      <p>The page you are trying to reach does not exist.</p>
      <p>
        Please click&nbsp;
        <Link to="/">here</Link> to return home.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
